import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container} from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import imgAS from "../assets/image/l6/png/messenger.png";
import imgGP from "../assets/image/l6/png/instagram.png";
import MapGoogle from "../components/MapGoogle";

const Kontakt = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
            theme: "light",
            align: "right",
            variant: "danger",
            isFluid: true,
            button: "null", // cta, account, null
          }}
          footerConfig={{
            style: "style1", //style1, style2
          }}
      >
        <div className="inner-banner pt-29 pb-md-10">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9">Na kontaktoni</h2>
                  <p className="gr-text-8 mb-10">
                  Doni këshilla rreth përdorimit të barnave? 
                  Kontakto farmacistin tonë, apo na vizitoni në lokalin tonë për shërbime live!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className=" pb-5 pb-md-0 ">
          <Container>
            <Row className="justify-content-center">
              <Col md="6" lg="5" xl="4" className="order-md-2 mt-lg-11">
                <Row className="justify-content-center">
                  <Col xs="6" md="12" lg="10" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon  gr-text-6 text-primary mr-7 mt-1">
                        <i className="icon icon-pin-3-2"></i>
                      </div>
                      <div className="widget-text">
                        <p className="gr-text-7 mb-0">
                          Rruga Sali Çeku,
                          <br />
                          afër Gjykatës Themelore, Ferizaj.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

              <div className="download-block mt-2">
              <Container>
                <Row className="justify-content-center">
                <div className="download-btns d-flex flex-column flex-xs-row justify-content-center mt-5">
                    <a href="https://m.me/narapharma" target="_blank">
                      <img
                        src={imgAS}
                        alt=""
                        className="mr-xs-10 mb-5 mb-xs-10"
                      />
                    </a>
                    <a href="https://www.instagram.com/narapharma/" target="_blank">
                      <img src={imgGP} alt="" className="mr-xs-10 mb-5 mb-xs-10" />
                    </a>
                </div>
                </Row>
                </Container>
                </div>
        <div
          className="google-map fluid-map-height position-relative overflow-hidden"
          id="googleMap"
          css={`
            position: relative;
            overflow: hidden;
          `}
        >
          <div
            css={`
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              background-color: rgb(229, 227, 223);
            `}
          >
            <MapGoogle />
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Kontakt;
